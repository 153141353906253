import { faCalculator } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import React from 'react';
import Banner from '../../../../components/Banner/Banner';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import * as styles from './Calculators.module.scss';

interface Props {
    hideBanner?: boolean;
}

const Calculators: React.FC<Props> = ({ hideBanner }) => {
    return (<>
        {hideBanner ? <></> : <>
            <Banner />
            <Breadcrumb Trail={[{
                Text: "Calculators",
                To: ""
            }]} />
        </>}

        <div className={styles.Calculators}>
            <h1>Calculators</h1>
            <p>A few simple to use Calculators that help you work out what is your statutory requirements as a business to your employees, such as working out annual leave for a new employee.</p>
            <hr />

            <div className={styles.CalculatorsInner}>
                <div className={styles.Card}>
                    <i>
                        <FontAwesomeIcon icon={faCalculator} />
                    </i>

                    <h1>Statutory Maternity Pay Calculator</h1>

                    <p>A simple to use tool to calculate your employee's <b>Statutory Maternity Pay</b> entitlemment and eligibility.</p>

                    <Link to={(hideBanner ? "/Portal/Tools/Calculators/Maternity Pay Calculator" : "/Calculators/Maternity-Pay-Calculator")}>
                        Use Calculator
                    </Link>
                </div>

                <div className={styles.Card}>
                    <i>
                        <FontAwesomeIcon icon={faCalculator} />
                    </i>

                    <h1>Statutory Annual Leave Calculator</h1>

                    <p>A simple to use tool to calculate your employee's <b>Statutory Annual Leave Pay</b> entitlemment and eligibility.</p>

                    <Link to={(hideBanner ? "/Portal/Tools/Calculators/Annual Leave Calculator" : "/Calculators/Annual-Leave-Calculator")}>
                        Yearly
                    </Link>
                    <Link to={(hideBanner ? "/Portal/Tools/Calculators/Hourly Leave Calculator" : "/Calculators/Hourly-Leave-Calculator")}>
                        Hourly
                    </Link>
                </div>

                <div className={styles.Card}>
                    <i>
                        <FontAwesomeIcon icon={faCalculator} />
                    </i>

                    <h1>Statutory Redundancy Pay Calculator</h1>

                    <p>A simple to use tool to calculate your employee's <b>Statutory Redundancy Pay</b> entitlemment and eligibility.</p>

                    <Link to={(hideBanner ? "/Portal/Tools/Calculators/Redundancy Pay Calculator" : "/Calculators/Redundancy-Pay-Calculator")}>
                        Use Calculator
                    </Link>
                </div>
            </div>
        </div>
    </>);
};

export default Calculators;
