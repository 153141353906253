import React from 'react';
import Layout from '../components/Layout/layout';
import Calculators from '../Views/Portal/Tools/Calculators/Calculators';

const CalculatorsPage: React.FC = () => {
  return (
    <Layout>
      <Calculators />
    </Layout>
  );
};

export default CalculatorsPage;
